
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { FormInput, FormSelect, FormPhone, FormRadio } from "@/components/forms";
import { Getter } from "vuex-class";

@Component({
    components: {
        FormInput,
        FormSelect,
        FormPhone,
        FormRadio,
    },
})
export default class AppointmentLocationPart extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("currentEntityOffices") offices!: any[];

    @Prop({ default: false }) disabled?: boolean;
    @Prop({
        default: () => {
            return {
                street: "",
                number: "",
                additional: "",
                zip: "",
                city: "",
            };
        },
    })
    value?: {
        street: string;
        number: string;
        additional: string;
        zip: string;
        city: string;
    };

    officeOptions = [];

    selectedOfficeOrAddress = "";

    localValue = {
        street: "",
        number: "",
        additional: "",
        zip: "",
        city: "",
    };

    get addresses() {
        const propertyAddress = this.activity.property_address;

        return [
            {
                label: `${propertyAddress.zip} ${propertyAddress.city} ${propertyAddress.street} ${propertyAddress.number}`,
                value: "property_address",
                formatted_address: `${propertyAddress.zip} ${propertyAddress.city} ${propertyAddress.street} ${propertyAddress.number}`,
            },
            ...(this.offices ?? []).map((office) => ({
                label: office.name,
                value: `office_${office.id}`,
                formatted_address: `${office.address.zip} ${office.address.city} ${office.address.street} ${office.address.number}`,
            })),
            { label: "Ander adres", value: "other" },
        ];
    }

    handleOfficeOrOtherChanged() {
        if (!this.selectedOfficeOrAddress) {
            this.localValue = {
                street: "",
                number: "",
                additional: "",
                zip: "",
                city: "",
            };

            this.handleInput();

            return;
        }

        if (`${this.selectedOfficeOrAddress}`.startsWith("office_")) {
            const officeId = `${this.selectedOfficeOrAddress}`.replace("office_", "");
            const office = this.offices.find((office) => office.id === Number(officeId));
            if (office) {
                this.localValue = {
                    street: office.address.street,
                    number: office.address.number,
                    additional: office.address.bus,
                    zip: office.address.zip,
                    city: office.address.city,
                };
            }

            this.handleInput();

            return;
        }

        if (`${this.selectedOfficeOrAddress}`.startsWith("property_address")) {
            const address = this.activity.property_address;

            this.localValue = {
                street: address.street ?? "",
                number: address.number ?? "",
                additional: address.additional ?? "",
                zip: address.zip ?? "",
                city: address.city ?? "",
            };

            this.handleInput();

            return;
        }

        this.localValue = {
            street: "",
            number: "",
            additional: "",
            zip: "",
            city: "",
        };
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: null) {
        this.localValue = newValue ?? {
            street: "",
            number: "",
            additional: "",
            zip: "",
            city: "",
        };

        // @ts-ignore
        let address = this.addresses.find((o) => o.formatted_address === `${this.localValue.zip} ${this.localValue.city} ${this.localValue.street} ${this.localValue.number}`);

        if (address) {
            // @ts-ignore
            this.selectedOfficeOrAddress = address.value;
        }
    }
}
